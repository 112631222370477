import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner> & {
	theme?: "light" | "dark" | "system"
}

/**
 * Toaster component.
 *
 * @component
 * @param {ToasterProps} props - The props for the Toaster component.
 * @param {string} props.theme - The theme for the Toaster component.
 * @returns {JSX.Element} The rendered Toaster component.
 */
const Toaster = ({ theme = "system", ...props }: ToasterProps) => {
	const { className = "toaster group", toastOptions = {}, ...restProps } = props

	return (
		<Sonner
			theme={theme}
			className={className}
			toastOptions={{
				classNames: {
					toast:
						"group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
					description: "group-[.toast]:text-muted-foreground",
					actionButton:
						"group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
					cancelButton:
						"group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
				},
				...toastOptions,
			}}
			{...restProps}
		/>
	)
}

export { Toaster }
