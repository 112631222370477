import { useEffect, useRef, useState } from "react"
import { useNavigation } from "@remix-run/react"
import { useSpinDelay } from "spin-delay"

import { Icon } from "@sagaware/ui-icons/Icon"
import { cn } from "@sagaware/utils/cn"

function ProgressBar() {
	const transition = useNavigation()
	const busy = transition.state !== "idle"
	const delayedPending = useSpinDelay(busy, {
		delay: 600,
		minDuration: 400,
	})
	const ref = useRef<HTMLDivElement>(null)
	const [animationComplete, setAnimationComplete] = useState(true)

	useEffect(() => {
		if (!ref.current) return
		if (delayedPending) setAnimationComplete(false)

		const animationPromises = ref.current
			.getAnimations()
			.map(({ finished }) => finished)

		void Promise.allSettled(animationPromises).then(() => {
			if (!delayedPending) setAnimationComplete(true)
		})
	}, [delayedPending])

	return (
		// biome-ignore lint: <explanation>
		<div
			role="progressbar"
			aria-valuenow={delayedPending ? 0 : undefined}
			aria-valuemin={0}
			aria-valuemax={1}
			aria-hidden={delayedPending ? undefined : true}
			aria-valuetext={delayedPending ? "Loading" : undefined}
			className="animate-pulse fixed inset-x-0 top-0 z-50 h-[0.20rem]"
		>
			<div
				ref={ref}
				className={cn(
					"h-full w-0 bg-foreground duration-500 ease-in-out",
					transition.state === "idle" &&
						(animationComplete
							? "transition-none"
							: "w-full opacity-0 transition-all"),
					delayedPending && transition.state === "submitting" && "w-5/12",
					delayedPending && transition.state === "loading" && "w-8/12",
				)}
			/>
			{delayedPending && (
				<div className="absolute flex items-center justify-center">
					<Icon
						// name="update"
						name="RefreshCcw"
						size="md"
						className="animate-spin m-1 text-foreground"
						aria-hidden
					/>
				</div>
			)}
		</div>
	)
}

export { ProgressBar }
