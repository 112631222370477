import { createContext, useContext } from "react"

export const NonceContext = createContext<string>("")

export const NonceProvider = NonceContext.Provider

export const useNonce = (): string => {
	const context = useContext(NonceContext)
	if (context === undefined) {
		throw new Error("useNonce must be used within a NonceProvider")
	}
	return context
}
