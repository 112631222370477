import type {
	HeadersFunction,
	LinksFunction,
	LoaderFunctionArgs,
	MetaFunction,
} from "@remix-run/node"
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	json,
	useLoaderData,
	useRouteError,
} from "@remix-run/react"
import nProgressStyles from "nprogress/nprogress.css?url"
import { HoneypotProvider } from "remix-utils/honeypot/react"

import { API } from "@sagaware/api"
import { getEnv } from "@sagaware/services/env.server"
import type { Theme } from "@sagaware/services/remix-theme.server"
import { getTheme } from "@sagaware/services/remix-theme.server"

import {
	GeneralErrorBoundary,
	MyErrorBoundary,
} from "./components/ErrorBoundary"
import { ProgressBar } from "./components/ProgressBar"
import { Toaster } from "./components/Toaster"
import { useNonce } from "./context/nonce-provider"
import { useToast } from "./hooks/use-toaster"
import { useTheme } from "./routes/resources.theme-switch"
import tailwindStyleSheetUrl from "./styles/tailwind.css?url"
import { getUserId, logout } from "./utils/auth.server"
import { ClientHintCheck, getHints } from "./utils/client-hints"
import { honeypot } from "./utils/honeypot.server"
import { combineHeaders, getDomainUrl } from "./utils/misc"
import { makeTimings, time } from "./utils/timing.server"
import { getToast } from "./utils/toast.server"

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		// { rel: 'preload', href: iconsHref, as: 'image' },
		{ rel: "mask-icon", href: "/favicons/mask-icon.svg" },
		{
			rel: "alternate icon",
			type: "image/png",
			href: "/favicons/favicon-32x32.png",
		},
		{ rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
		{
			rel: "manifest",
			href: "/site.webmanifest",
			crossOrigin: "use-credentials",
		} as const, // necessary to make typescript happy
		{ rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
		{ rel: "stylesheet", href: tailwindStyleSheetUrl },
		{ rel: "stylesheet", href: nProgressStyles },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? "Saga OS" : "Error | Saga OS" },
		{ name: "description", content: `Your own captain's log` },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings("root loader")
	const userId = await time(() => getUserId(request), {
		timings,
		type: "getUserId",
		desc: "getUserId in root",
	})

	const user = userId
		? await time(() => API.userRepository.getOne(userId), {
				timings,
				type: "find user",
				desc: "find user in root",
			})
		: null

	if (userId && !user) {
		console.info("something weird happened")
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: "/" })
	}

	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ "Server-Timing": timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		"Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
	}
	return headers
}

function Document({
	children,
	nonce,
	theme = "dark",
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}) {
	return (
		<html lang="en" className={`${theme} h-full`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body>
				{children}
				<script
					nonce={nonce}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const theme = useTheme()
	const allowIndexing = data.ENV.ALLOW_INDEXING !== "false"
	useToast(data.toast)

	return (
		<Document
			nonce={nonce}
			theme={theme}
			allowIndexing={allowIndexing}
			env={data.ENV}
		>
			<div className="flex h-screen flex-col justify-between">
				<Outlet />
				<Toaster closeButton={false} position="top-right" theme={theme} />
				<ProgressBar />
			</div>
		</Document>
	)
}

export default function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	const error = useRouteError()
	console.error(error)
	return (
		<Document nonce={nonce}>
			<div className="flex min-h-screen items-center justify-center bg-gray-100">
				<div className="rounded-lg bg-white p-6 shadow-lg">
					<h1 className="mb-4 text-2xl font-bold text-red-600">Oh no!</h1>
					<pre className="overflow-x-auto rounded bg-gray-200 p-4">
						<GeneralErrorBoundary />
						<code className="text-sm">{(error as Error).message}</code>
					</pre>
				</div>
			</div>
			<MyErrorBoundary />
		</Document>
	)
}
